/*
    PDF Lib Utilities methods
*/

import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import "./pdfjs";

frappe.provide("fillable_pdf.controllers");

fillable_pdf.controllers.PDFDocumentController = class PDFDocumentController{
    constructor(args){
        $.extend(this, args);
        this.set_defaults();
    }
    
    set_defaults(){
        this.searchParams = new URL(window.location.href ).searchParams;
        this.$wrapper = $(`<section>
            <button type="button" class="btn-download next btn btn-dark m-3 primary-action-btn next-no-fade btn-lg" style="background-image: none;">Save</button>
            <button type="button" class="btn-attach-to-document next btn btn-dark m-3 primary-action-btn next-no-fade btn-lg" style="background-image: none;">Attach To Record</button>
        </section>`).appendTo("main");

        this.$download_btn = this.$wrapper.find(".btn-download");
        this.$attach_to_document_btn = this.$wrapper.find(".btn-attach-to-document");
        this.$pdfiframe= $(`<iframe id="pdfiframe" style="width: 100%; height: 1000px;" type="application/pdf"></iframe>`).appendTo("main");
        this.$download_btn.on("click", async (e)=>{
            e.preventDefault();
            console.log(await this.get_fields());
            return false;
        });
    }

    async make_document(){
        this.file_doc = await this.get_file_doc();
        if(this.file_doc.file_url){
            this.pdfDoc = await this.load_from_url();
        }else if(this.file_doc.pdf_bytes){
            this.pdfDoc = await this.load_from_bytes();
        }else{
            this.pdfDoc = await PDFDocument.create();
        }

        this.$pdfiframe.attr("src", this.get_pdf_full_url(this.file_doc.file_url));
        this.add_iframe_event_handler();
        return this.pdfDoc;
    }

    add_iframe_event_handler(){
        this.$pdfiframe.on("load", (e)=>{
            console.log("navdeep here");
            this.$pdfiframe.contents().find("input").on("change", (e)=>{
                alert();
            });
        });
        
    }

    async load_from_url(){
        this.pdf_bytes = await fetch(this.file_doc.file_url).then(res => res.arrayBuffer());
        return this.load_from_bytes();
    }

    async load_from_bytes(){
        return PDFDocument.load(this.pdf_bytes, {
            "ignoreEncryption": true
        });
    }

    async add_page(){
        if(!this.pdfDoc){
            // backward compatibility
            this.make_document();
        }
        const page = this.pdfDoc.addPage()
        const { width, height } = page.getSize()
        const fontSize = 30
        page.drawText('Creating PDFs in JavaScript is awesome!', {
            x: 50,
            y: height - 4 * fontSize,
            size: fontSize,
            font: timesRomanFont,
            color: rgb(0, 0.53, 0.71),
        });

        const pdfBytes = await pdfDoc.save()
        return page;
    }

    get_file_doc(){
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "bond_assets.fillable_pdf.get_file_data",
                args:{
                    filename: this.get_filename()
                },
                callback: (res)=>{
                    resolve(res.message);
                },
                error:()=>{
                    reject();
                }
            });
        });
    }

    get_filename(){
        return this.searchParams.get("filename");
    }

    async get_fields(){
        let lastest_data = await this.update_pdfDoc();
        console.log(lastest_data);
        const form = this.pdfDoc.getForm();
        window._form = form;
        window.pdfDoc = this.pdfDoc;
        const fields = form.getFields();
        let _fields = [];
        fields.forEach(field => {
            _fields.push(this.get_field_data(field));
        });
        window._form = form;
        window.fields = fields;
        return _fields;
    }

    get_field_data(field){
        let fieldtype = field.constructor.name;
        let fieldname = field.getName();
        let value;
        try{
            if(in_list(["PDFCheckBox2", "PDFCheckBox"], fieldtype)){
                value = field.isChecked();
            }else if(in_list(["PDFTextField2", "PDFTextField"], fieldtype)){
                value = field.getText();
            }else if(in_list(["PDFRadioGroup2", "PDFRadioGroup"], fieldtype)){
                value = field.getSelected();
            }
        }catch(e){
            console.log(`${fieldtype}: ${e}`);
        }
        return {
            fieldtype: fieldtype,
            fieldname: fieldname,
            value: value,
        };
    }

    async update_pdfDoc(){
        let data = await this.pdfDoc.saveAsBase64({dataUri: true});
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "bond_assets.fillable_pdf.modify_pdf",
                args:{
                    filename: this.get_filename(),
                    data: data
                },
                callback: (res)=>{
                    resolve(res.message);
                }
            });
        });
    }

    get_pdf_full_url(file_url){
       return frappe.urllib.get_full_url(file_url);
    }


}