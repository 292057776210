frappe.provide("bond_assets.pdfjs");


window.pdfjsLib = require('pdfjs-dist/build/pdf');
window.pdfjsWorker = require('pdfjs-dist/build/pdf.worker');
window.pdfjsViewer = require("pdfjs-dist/web/pdf_viewer")

const CMAP_URL = "node_modules/pdfjs-dist/cmaps/";
const CMAP_PACKED = true;

const SCALE = 3;

const ENABLE_XFA = true;

bond_assets.pdfjs.DocumentController = class PDFDocumentController{

    constructor(args){
        $.extend(this, args);
        this.remove_external_elements();
        this.make();
    }

    remove_external_elements(){
        $("header").remove();
        $("footer").remove();
    }

    async make(){
        this.$pdfwrapper  = $("#viewerContainer")
        this.searchParams = new URL(window.location.href ).searchParams;
        this.$save_btn = $(`<button class="save-pdf-btn">Save</button>`).appendTo(".pdf-actions");
        this.$validate_btn = $(`<button class="validate-pdf-btn" style="display: none;">Validate</button>`).appendTo(".pdf-actions");
        if(this.only_validate()){
            this.$save_btn.css("display", "none");
            this.$validate_btn.css("display", "");
        }
        this.data = await this.get_data();
        this.set_data();
        this.set_global_ref();
    }

    set_global_ref(){
        bond_assets._pdfobj = this;

        $(window).bind('beforeunload', function() {
            try{
                this.pdfDoc.destroy();
                this.pdf_viewer.cleanup();
                alert();

            }catch(e){

            }
        }); 
    }

    async set_data(){
        this.pdfDoc = pdfjsLib.getDocument({
            url: this.get_file_url(),
            cMapUrl: CMAP_URL,
            cMapPacked: CMAP_PACKED,
            enableXfa: ENABLE_XFA,
        });
        let container = document.getElementById("viewerContainer");
        this.pdfDoc.promise.then((pdf)=>{
            this.pdf = pdf;
            const eventBus = new pdfjsViewer.EventBus();
            this.pdf_viewer = new pdfjsViewer.PDFViewer({
                container: container,
                scale: SCALE,
                eventBus: eventBus,
                annotationMode: pdfjsLib.AnnotationMode.ENABLE_FORMS,
                enablePermissions: true,
            });
            this.pdf_viewer.setDocument(pdf);
            this.$pdfwrapper.css("position", "relative");
            this.pdfDoc._transport.getFieldObjects().then((fields)=>{
                this.highlight_fields(fields);
            });
            this.add_event_handler();
        });
    }

    get_binary_data(){
        return this.data.binary_contents;
    }

    get_file_url(){
        return `${this.data.file_url}?_tag=${frappe.datetime.now_datetime()}`
    }

    add_event_handler(){
        this.pdf_viewer.currentScaleValue = 1.25;
        setTimeout(()=>{
            this.$pdfwrapper.find("input").on("change", (e)=>{
                if(this.show_pdf_fieldname()){
                    alert($(e.currentTarget).attr("name"));
                }
            });

            $(".download-pdf-btn").on("click", async (e)=>{
                let data = await this.get_modified_pdf_data();
                return false;
            });

            this.$save_btn.on("click", async (e)=>{
                let data = await this.get_modified_pdf_data();
                this.modify_pdf(data).then(()=>{
                    alert("PDF saved successfully.");
                });
                return false;
            });
            this.$validate_btn.on("click", (e)=>{
                try{
                    this.pdf.saveDocument().then((data)=>{
                        alert("Form validated successfully!");
                    }, (error)=>{
                        alert("Invalid file.");
                    });
                }catch(e){
                    console.log(e);
                }
                return false;
            });
        }, 2000);
    }

    highlight_fields(){
        setTimeout(()=>{
            if(this.data.fillable_form && this.data.fillable_form.fillable_form_fields){
                let elementName;
                let fields_to_be_highlights = [];
                this.data.fillable_form.fillable_form_fields.forEach((field)=>{
                    elementName = field.form_field_name;
                    if(cstr(elementName).indexOf("[0]") >= 0){
                        elementName = elementName.split("[0]")[0];
                    }
                    if(cint(field.highlight_field)){
                        if($(`[name='${elementName}']`).length){
                            fields_to_be_highlights.push(elementName);
                        }else{
                            fields_to_be_highlights.push(`F[0].P1[0].${elementName}`);
                        }
                    }
                });
                fields_to_be_highlights.forEach((fieldname)=>{
                    $(`[name='${fieldname}']`).css("background-color", "yellow");
                });
            }
        }, 2000);
    }

    async get_modified_pdf_data(type =  "application/pdf"){
        return new Promise((resolve, reject)=>{
            this.pdf.saveDocument().then((data)=>{
                let blob = new Blob([data], {type: type});
                var reader = new FileReader();
                reader.readAsDataURL(blob); 
                reader.onloadend = ()=> {
                    resolve(reader.result);
                }
            });
        });
    }

    get_data(){
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "bond_assets.fillable_pdf.get_file_data",
                args:{filename: this.get_filename()},
                callback: (res)=>{
                    resolve(res.message);
                },
                error: ()=>{
                    reject();
                }
            });
        });
    }

    modify_pdf(data){
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "bond_assets.fillable_pdf.modify_pdf",
                args:{
                    filename: this.get_filename(),
                    data: data
                },
                callback: (res)=>{
                    resolve(res.message);
                }
            })
        });
    }

    get_filename(){
        return this.searchParams.get("filename");
    }

    only_validate(){
        return cint(this.searchParams.get("for_validation"));
    }

    show_pdf_fieldname(){
        return cint(this.searchParams.get("show_fieldname"));
    }
};